import React from 'react'
import Helmet from 'react-helmet'
import Image from 'gatsby-image'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

const blogPost = ( { data } ) => (
  <Layout>
    <Helmet title={ `${data.contentfulBlogPost.title} | Rudd Joinery` }>
      <meta name="description" content={data.contentfulBlogPost.title} />
    </Helmet>
    <div className="section wrapper">
      <article className="blog-post">
        <header>
          <h1>
            { data.contentfulBlogPost.title }
          </h1>
          <p>
            <svg viewBox="0 0 68.29 68.29">
              <path fill="none" d="M34.14,2.53A31.62,31.62,0,1,0,65.76,34.14,31.61,31.61,0,0,0,34.14,2.53Zm-4,41c0,8-3.37,12.76-8.76,12.76l-.58-1.16c2.6-.38,5.11-4.19,5.11-9.1V20.8c0-2.06-.41-2.54-4-3.23v-1.2h8.18Zm20.13,4.91h-7l-.19-.14a34.11,34.11,0,0,0-3-5.3l-6-8.81h-2V32.35H34.2c2.89,0,6.21-2.31,6.21-7,0-3.66-2.07-7.12-8-7.12h-.28V16.37h1c8.67,0,11.94,3.56,11.94,7.75,0,4.53-4,7.61-6.36,8.81v.63l6.94,10.15c2.07,3,3,3.08,4.28,3.18l.53,0Z"/>
              <path d="M34.14,0A34.15,34.15,0,1,0,68.29,34.14,34.15,34.15,0,0,0,34.14,0Zm0,65.76A31.62,31.62,0,1,1,65.76,34.14,31.61,31.61,0,0,1,34.14,65.76Z"/>
              <path d="M45.66,43.71,38.72,33.56v-.63c2.31-1.2,6.36-4.28,6.36-8.81,0-4.19-3.27-7.75-11.94-7.75h-1v1.88h.28c5.92,0,8,3.46,8,7.12,0,4.67-3.32,7-6.21,7H32.14v1.88h2l6,8.81a34.11,34.11,0,0,1,3,5.3l.19.14h7l.19-1.54-.53,0C48.69,46.79,47.73,46.7,45.66,43.71Z"/>
              <path d="M22,17.57c3.54.69,3.92,1.17,4,3.23V46.07c0,4.91-2.51,8.72-5.11,9.1l.58,1.16c5.39,0,8.76-4.72,8.76-12.76V16.37H22Z"/>
            </svg>
            <strong>Rudd Joinery</strong>, on <strong>{data.contentfulBlogPost.publishDate}</strong>
          </p>
          <div>
          <Image alt="" fluid={data.contentfulBlogPost.coverImage.fluid} />
        </div>
        </header>        
        <div className="content story">          
          <div dangerouslySetInnerHTML={{ __html: data.contentfulBlogPost.content.childMarkdownRemark.html }} />
        </div>
      </article>
    </div>
    <div className="section cta">
      <Link to="/blog" className="cta">View all articles</Link>
    </div>
  </Layout>
)

export default blogPost

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "Do MMMM, YYYY")
      coverImage {
        fluid(maxWidth: 1600) {
          aspectRatio
          sizes
          src
          srcSet
          srcWebp
          srcSetWebp
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`